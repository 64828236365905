import { ErrorResponse } from 'apollo-link-error';
import { toast } from "react-toastify";
import { I18n } from "react-redux-i18n";
import { navigationService as navigator } from "@navigation";
import { persistedStore, store, BrandThemeActions, LoaderActions } from "@redux";
import { getApolloClient } from "../apollo";
import {
  AccountActions,
  AccountScreens,
  AuthActions,
  BookingActions,
  HomeScreens,
  OffersActions,
  PaymentsActions,
  PreferredZonesThunks,
  FiltersActions
} from "@modules";

export enum ErrorType {
  FORBIDDEN = "FORBIDDEN",
  QUOTATION_ERROR = "QUOTATION_ERROR",
  ORDER_INVALID = "ORDER_INVALID",
  AUTH_REQUIRED = "AUTH_REQUIRED",
  TEMPORARY_CODE_INVALID = "TEMPORARY_CODE_INVALID",
  TEMPORARY_CODE_EXPIRED = "TEMPORARY_CODE_EXPIRED",
  TEMPORARY_CODE_OFFER_INVALID = "TEMPORARY_CODE_OFFER_INVALID",
  TEMPORARY_CODE_OFFER_TEMPLATE_INVALID = "TEMPORARY_CODE_OFFER_TEMPLATE_INVALID",
  BADGE_NOT_FOUND = "Badge not found",
  USER_NOT_FOUND_IN_SCOLAPASS_DB = "User not found on scolapass db",
  USER_NOT_ASSOCIATED_WITH_HOLDING = "User not associated with this holding",
  FILE_NOT_FOUND = 'FILE NOT FOUND',
  NO_POINTAGE_ACCOUNT_CREATED = "No Pointage account defined in BackOffice",
}

const ignoreError = (graphQLError: any) => {
  return (
    graphQLError.message &&
    [
      ErrorType.ORDER_INVALID.toString(),
      ErrorType.TEMPORARY_CODE_INVALID.toString(),
      ErrorType.TEMPORARY_CODE_EXPIRED.toString(),
      ErrorType.AUTH_REQUIRED.toString(),
    ].includes(graphQLError.message)
  );
};

const handleLogout = async () => {
    await persistedStore.purge();

    await getApolloClient().cache.reset();

    store.dispatch(AuthActions.logOutUser());
    store.dispatch(AccountActions.setUserBalance(null));
    store.dispatch(BookingActions.initBookingOrders());
    store.dispatch(OffersActions.initOrderItems());
    store.dispatch(BrandThemeActions.resetBrandTheme());
    store.dispatch(PaymentsActions.resetPayment());
    store.dispatch(PreferredZonesThunks.resetPreferredZonesStore());
    store.dispatch(FiltersActions.resetState());

    localStorage.removeItem("persist:root");
    localStorage.clear();

    document.cookie =
      "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    
    await persistedStore.flush();

    navigator.navigate(HomeScreens.HOME_SCREEN);
  };

const onError = (error: ErrorResponse): void => {
  const env = window.config;

  if (error.graphQLErrors) {
    error.graphQLErrors
      .filter((error: any) => !ignoreError(error))
      .forEach((graphQLError: any): void => {
        // @todo: cleanup => most error codes dont exist anymore
        switch (graphQLError.message) {
          case ErrorType.QUOTATION_ERROR:
            toast.error(graphQLError ?? I18n.t("errorHandler.generic"))
            break;
          case ErrorType.NO_POINTAGE_ACCOUNT_CREATED:
            store.dispatch(AuthActions.setHavePointageAccount(false));
            break;
          case "ALREADY_COMPLETED":
            toast.error(graphQLError ?? I18n.t("errorHandler.generic"))
            break;
          case "ALREADY_CANCELLED":
            toast.error(graphQLError ?? I18n.t("errorHandler.generic"))
            break;
          case "ARTICLE_OUT_OF_STOCK":
            toast.error(graphQLError ?? I18n.t("errorHandler.generic"))
            break;
          case "OUT_OF_STOCK":
            // catch the error and do nothing, do not trigger a notification
            toast.error(graphQLError ?? I18n.t("errorHandler.generic"))
            break;
          case "NOT_CANCELABLE":
            toast.error(graphQLError ?? I18n.t("errorHandler.generic"))
            break;
          case "NOT_CONFIRMED":
            toast.error(graphQLError ?? I18n.t("errorHandler.generic"))
            break;
          case "STOCK_TOO_LOW":
            toast.error(graphQLError ?? I18n.t("errorHandler.generic"))
            break;
          case "DUPLICATED_ORDER_RATING":
            toast.error(I18n.t("errorHandler.generic"))
            break;
          case "EXPIRED_AUTH":
          case "INVALID_AUTH":
          case "FORBIDDEN":
            if (error.operation.operationName === "getUserProfile") {
              store.dispatch(LoaderActions.setLoading(false));
              navigator?.navigate(HomeScreens.ACCOUNT_STACK, {
                screen: AccountScreens.STEP_ONE_SCREEN,
              });
              break;
            }
            if (error.operation.operationName !== "getI18nProviderLocale") {
              navigator.navigate(HomeScreens.HOME_SCREEN);
            }
            // Get empty response from CS
            // because user has not a valid account
            if (error.response?.data?.mercanetV2 === null) {
              toast.error("You are not allowed to perform this request !" ?? I18n.t("errorHandler.generic"))
              navigator.navigate(HomeScreens.BALANCE_SCREEN);
            } else {
              handleLogout();
            }
            break;
          case "LOCKER_NOT_FOUND":
            // catch the error and do nothing, do not trigger a notification
            break;
          case "OTHER":
            // errors that have the code OTHER but need to be detailed in the app should be added here
            const detailedMessage =
              graphQLError?.extensions?.original?.details &&
              graphQLError.extensions.original.details.length > 0 &&
              graphQLError.extensions.original.details[0].message;

            if (
              detailedMessage === "slot is full" ||
              detailedMessage === "Offer must be published"
            ) {
              // we don't want to show a toast error in this case so we break the switch case
              break;
            }
            break;
          default:
            const originalMessage =
            graphQLError?.extensions?.original?.message;

            if (originalMessage === ErrorType.FILE_NOT_FOUND) {
              toast.error(I18n.t('errorHandler.noInvoiceFile'));
              break;
            }

            toast.error(graphQLError ?? I18n.t("errorHandler.generic"))
        }
      });
  } else if (error.networkError) {
    toast.error(error.networkError.message ?? I18n.t("errorHandler.generic"));
  }
};

export default { onError };
